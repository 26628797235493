import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { RestaurantService } from '../restaurant/restaurant.service';
import * as moment from 'moment';
// import { Plugins } from '@capacitor/core';
import {BehaviorSubject, forkJoin, from, Observable, of, ReplaySubject, Subject, throwError} from 'rxjs';
import {catchError, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import { OrderItem } from '../../pages/modal/cart/order-item.model';
import { Router } from '@angular/router';
import { Table } from '../../models/table.model';
import { TableService } from '../table/table.service';
import { Plugins } from '@capacitor/core';
import { CartExpiredPage } from '../../pages/modal/cart-expired/cart-expired.page';
import { MenuItemWithOptionsPage } from '../../pages/modal/menu-item-with-options/menu-item-with-options.page';
import { ModalController, NavController } from '@ionic/angular';
import { RequestTableNumberPage } from '../../pages/modal/table/request-table-number/request-table-number.page';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  orderCounter = 0;
  private _orders: OrderItem[] = [];
  public _orderHeadId: number = null;
  public idsInCart = [];
  public totalAmount: Subject<Number> = new ReplaySubject<Number>();

  public orderItems = new BehaviorSubject<OrderItem[]>([]);
  public _table = new BehaviorSubject<Table>(null);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private restaurantService: RestaurantService,
    private router: Router,
    private tableService: TableService,
    private modalCtrl: ModalController,
    public navCtrl: NavController
    ) {
      this.fetchCart().subscribe();
      this.retriveTable.subscribe();
    }

  getOrderItemByMenuId(menuItemId: number) {
    return this._orders.find((ord => ord.menu_item_id === menuItemId));
  }

  getorderItemQuantityBymenuId(menuItemId) {
    const orderItem = this.getOrderItemByMenuId(menuItemId);
    return orderItem ? orderItem.quantity : null;
  }

  addtoCart(menuItemId: number, qtd: number, options: any): Observable<boolean> {

    return new Observable(observer => {
      let index = -1;

      if (this._orders) {
        index = this._orders.findIndex((ord => ord.menu_item_id === menuItemId));
      }

      forkJoin([
        this.authService.userId.pipe(take(1)),
        this.orderHeadId.pipe(take(1))
      ]).pipe(
        switchMap(([userId, orderHeadId]) => {
          // TODO: we dont need userId here, orderHead already belongs to a user/guest
          return this.http.post(`${environment.api.url}/orderitem/user/${userId}/orderhead/${orderHeadId}`, {
            menuItemId: menuItemId,
            quantity: qtd,
            options
            // idIngredientOfExtraIngredient: null,
            // idIngredientOfRemovedIngredient: null
          }).pipe(
            catchError(this.handleError.bind(this))
          );
      }),
      switchMap((response: any) => {

        const orderItem: OrderItem = response.data.orderItem;
        return this.orderItems.pipe(
          take(1)
        );
      }),
      switchMap(() => this.fetchCart())
      ).subscribe(r => {
        observer.next(true);
      });
    });
  }

  handleError(error) {

    this.navCtrl.navigateForward('/places');
    return of();
    if(error.status != '410') {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
   
    } else {
      this.presentCartExpiredModal();      
      let errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      return of();//throwError(errorMessage);
    }
  }

  async openMenuOptionsModal(menuItem) {
    const modal = await this.modalCtrl.create({
      component: MenuItemWithOptionsPage,
      initialBreakpoint: 0.9,
      cssClass: "menu-options-modal",
      componentProps: {
        'menuItem': menuItem,
        'cartService': this,
      },
    });
    modal.present();
  }

  async presentCartExpiredModal()
  {
    const modal = await this.modalCtrl.create({
      component: CartExpiredPage,
      backdropDismiss: false,
      componentProps: {
        'restaurant': this.restaurantService.selectedPlace.pipe(take(1))
      }
    });

    modal.onDidDismiss().then((data: any) => {
      this.router.navigateByUrl('/places');
    });

    return await modal.present();
  }

  get totalValue(): number {
    let value = 0;
    if(this._orders) {
      this._orders.forEach(orderItem => {
        value += this.getOrerItemPrice(orderItem);
      });
    }

    return value;
  }

  getOrerItemPrice(orderItem) {
    let value = orderItem.price * orderItem.quantity;

    if (orderItem.children) {
      orderItem.children.forEach(child => {
        value += this.getOrerItemPrice(child);
      });
    }

    return value;
  }

  fetchCart(): Observable<boolean> {
    return new Observable(observer => {
      
      forkJoin([
        this.authService.userId.pipe(take(1)),
        this.orderHeadId
      ]).pipe(
        switchMap(([userId, orderHeadId]) => {
          if (userId && orderHeadId) {
            const languageId = 1;
            return this.http.get(`${environment.api.url}/orderhead/${orderHeadId}`)
            .pipe(
              tap((result: any) => {
                this.totalAmount.next(result.orderHead.totalAmount);
              })
            );
          } else {
            return [];
          }
      }),
      tap((result: any) => {
        if (result) {
          result.orderHead.order_items.forEach(orderItem => {
            this.restaurantService.getMenuItemById(orderItem.id).then((menuItem: any) => {
              orderItem.name = menuItem.name;
              orderItem.description = menuItem.description;
            });
          });
        }
        console.log(result.orderHead);

        this.orderItems.next(result.orderHead.order_items.filter(o => o.parent_id === null));
        return result.orderHead.order_items;
      })
      ).subscribe(
        (result: any) => {
          this._orders = result.orderHead.order_items ? result.orderHead.order_items : [];
          observer.next(true);
        }
      );
    });
  }

  removeOrderHead()  {
    this._orderHeadId = null;
  }

  setOrderHeadIdByRestaurant() {
    this.orderHeadId.subscribe(orderHeadId => {
      this._orderHeadId = orderHeadId;
    });
  }

  getOrdersQuantity() {
    let count = 0;
    if (this._orders) {
        this._orders.forEach(orderItem => {
            count += orderItem.quantity;
        });
    }
    return count;
}

  getOrders(): OrderItem[] {
    return this._orders ? this._orders : [];
  }

  removefromCart(orderItem) {
    const index = this._orders.indexOf(orderItem);
    if (index > -1) {
      this._orders.splice(index, 1);
    }

    const url = `${environment.api.url}/orderitem/${orderItem.id}`;
    return this.http.delete(url, {});
  }

  increaseOrderItemQuantity(orderItem) {
    this.editQtdOrder(orderItem, 'plus');
  }

  decreaseOrderItemQuantity(orderItem) {
    this.editQtdOrder(orderItem, 'minus');
  }

  editQtdOrder(orderItem, op) {            
    const orders = this.orderItems.pipe(
      take(1),
      tap(orderItems => {
        const orders: OrderItem[] = orderItems.slice();

        orders.forEach((order) => {
          if (order.id === orderItem.id) {

            if (op === 'minus' && order.quantity > 0) {
              order.quantity--;
            }
            
            if (op === 'plus') {
              order.quantity++;
            }
          }
        });

        return orders;
      }),
      tap(orderItems => {
        this.orderItems.next(orderItems);
        return orderItems;
      }),
      mergeMap(orderItems => {
        const _orderItem = orderItems.find(o => o.id == orderItem.id);
        const url = `${environment.api.url}/orderitem/${orderItem.id}`;

        if (_orderItem) {
          if (_orderItem.quantity > 0) {
            return this.http.post(url, {
              'quantity': _orderItem.quantity
            });
          }

          if (!_orderItem.quantity) {
            const index = orderItems.findIndex(o => o.id == orderItem.id);
            orderItems.splice(index, 1);
  
            this.orderItems.next(orderItems);
            return this.http.delete(url);
          }
        }
      }),
      switchMap(_ => this.fetchCart())
      ).subscribe();
  }

  cleanCart() {
    this._orders = [];
  }

  getItemQuantity(menuItem) {
    let index = -1;
    
    if (this._orders) {
      index = this._orders.findIndex((ord => ord.menu_item_id === menuItem.id));
    }

    return index === -1 ? null : this._orders[index].quantity;
  }

  emptyOrderHead() {
    this._orderHeadId = null;
  }

  get orderHeadId(): Observable<number> {

    if (!this._orderHeadId) {
      return this.fetchOrderHeadFromServer().pipe(take(1),
        map((orderHead: any) => {
          if (orderHead > 0) {
            this._orderHeadId = orderHead;
            return orderHead;
          }
          return null;
        })
      );

    } else {
      return of(this._orderHeadId);
    }
  }

  getTableById(id: number): Observable<Table> {
    return this.restaurantService.selectedPlace.pipe(
      map(restaurant => {
        const table = restaurant.tables.filter(t => t.id === id);
        if (!table.length) {
          throw `No table found by id: ${id}`;
          return null;
        }
        return new Table(id, restaurant.id, table[0].number, table[0].name);
      })
    );
  }

  async presetntRequestTableModal(restaurantId: number)
  {
    const modal = await this.modalCtrl.create({
      component: RequestTableNumberPage,
      backdropDismiss: false,
      componentProps: {
        'restaurantId': restaurantId,
        'tableObservable': this.table
      }
    });

    modal.onDidDismiss().then((data: any) => {
      this.setSelectedTable(data.data);
    });

    return await modal.present();
  }

  emptyCart() {
    this._orders = [];
    this._orderHeadId = null;
    this.idsInCart = [];
  
    this.orderItems.next([]);
  }

  fetchOrderHeadFromServer(): Observable<number> {
    return this.restaurantService.selectedPlace.pipe(
      take(1),
      switchMap(venue => {
        return this.authService.userId.pipe(take(1),
          switchMap(guestId => {
            if (venue && guestId) {
              return this.http.get<{orderHeadId: number, hasTableNumber: number}>(
                `${environment.api.url}/user/${guestId}/orderhead/${venue.id}/getorderhead`).pipe(
                  map((response) => {
                    if (response && response[0]) {
                      return response[0].id;
                    }
                  }));
            } else {
              return of(-1);
            }
        }));
      })
    );
  }

  getNewOrderHead(): Observable<number> {

    return forkJoin([
      this.restaurantService.selectedPlace.pipe(take(1)),
      this.authService.userId.pipe(take(1))
    ]).pipe(
      switchMap(([venue, userId]) => {
        if (venue && userId) {
          return this.http.get<any>(
            `${environment.api.url}/orderhead/getnew/user/${userId}/restaurant/${venue.id}`).pipe(
              map((orderHead:any) => {
                this._orderHeadId = orderHead.id;
                this.orderItems.next([]);
                this._orders = [];
                
                

                return orderHead.id;
              }));
        } else {
          if (!venue) {
            console.error("No venue found");
          }
          if (!userId) {
            console.error("No user found");
          }
          return of(null);
        }
       
      }));
  }

  get table () {
    return this._table.asObservable().pipe(map(table => {
      if (table) {
        return table;
      } else {
        return null;
      }
    }));
  }

  setSelectedTable(table: Table) {
    const data = {
      table: table,
      timeOfSet: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    this._table.next(table);
    Plugins.Storage.set({key: 'selected-table', value: JSON.stringify(data)});
    const datat = { tableId: table.id };
    this.orderHeadId.pipe(
      switchMap(orderHeadId => {
        return this.http.post(environment.api.url + `/orderhead/${orderHeadId}/settable`, datat);
      })
      ).subscribe();
  }

  unsetTable() {
    this._table.next(null);
    Plugins.Storage.remove({key: 'selected-table'});
  }

  validateStoredTable(): Observable<boolean> {

    return forkJoin([
      this.restaurantService.selectedPlace,
      this.retriveTable]
    ).pipe(map( ([restaurant, storedTable]) => {
      if (storedTable && storedTable.table && storedTable.timeOfSet) {
        const now = moment(new Date()); 
        const timeOfSet = moment(storedTable.timeOfSet);
        const duration = moment.duration(now.diff(timeOfSet));
        const hours = duration.asHours();
         if (hours < 4 && (+restaurant.id === +storedTable.table.restaurant_id)) {
          return true;
        }
      }
      return false;
    }));
  }
  
  get retriveTable () {
    return from(Plugins.Storage.get({key: 'selected-table'})).pipe(
      map(storedData => {
        if (storedData) {
          const parsedData = JSON.parse(storedData.value);
          if (parsedData) {
            this._table.next(parsedData.table);
            return parsedData;
          }
          return null;
        }
      })
      );
  }  

  getOptionPrice(menuItem, quantity, options = {}) {
    return  this.http.post(`${environment.api.url}/menuitem/price/${menuItem}`, {
      options: options,
      quantity: quantity
    });
  }
}
