import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
  } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { take, switchMap, tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NavController } from '@ionic/angular';


@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private navCtrl: NavController
        ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.token.pipe(take(1), switchMap((token) => {
            let modifiedRequest;
            if (token) {
                const modifiedAuthRequest = req.clone({
                    setHeaders: {
                        'Authorization': token
                    },
                    withCredentials: true
                });
                modifiedRequest = modifiedAuthRequest;
            } else {
                modifiedRequest = req;
            }

            return next.handle(modifiedRequest).pipe(tap(evt => {
            }),
            catchError((err: any) => {
                if (err.status === 401) {
                    this.authService.logout();
                    this.navCtrl.navigateForward('/login');
                }
                return of(err);
            })
            );
       }));
    }
}
