import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';

// Services/Providers
import { TranslateProvider, RestaurantService } from './providers';
import { AuthInterceptorService } from './auth/auth-interceptorservice';
import { SettingsService } from './providers/settings/settings.service';

// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { CartPageModule } from './pages/modal/cart/cart.module';
import { QRScannerPageModule } from './pages/modal/qr-scanner/qr-scanner.module';
import { RequestTableNumberPageModule } from './pages/modal/table/request-table-number/request-table-number.module';
import { LocationPageModule } from './pages/modal/location/location.module';
import { SearchFilterPageModule } from './pages/modal/search-filter/search-filter.module';
import { WonderPush } from '@ionic-native/wonderpush/ngx';

// Environment
import { environment } from '../environments/environment';

// Components
import { NotificationsComponent } from './components/notifications/notifications.component';

// Pipes
import { PipesModule } from './pipes/pipes.module';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import localeFr from '@angular/common/locales/fr';
import { RestaurantInfoPageModule } from './pages/modal/restaurant-info/restaurant-info.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
registerLocaleData(localeFr, 'fr');
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ThankYouPageModule } from './pages/modal/thank-you/thank-you.module';
import { UnderEighteenPageModule } from './pages/modal/under-eighteen/under-eighteen.module'; 
import { CartExpiredPageModule } from './pages/modal/cart-expired/cart-expired.module';
import { OrderRefusedPageModule } from './pages/modal/order-refused/order-refused.module';
import { PaymentFailedPageModule } from './pages/modal/payment-failed/payment-failed.module';
import { MenuItemWithOptionsPageModule } from './pages/modal/menu-item-with-options/menu-item-with-options.module';
import { Network } from '@ionic-native/network/ngx';

import { AnalyticsService } from './providers/analytics/analytics.service';
import { QRScannerPage } from './pages/modal/qr-scanner/qr-scanner.page';
import { AuthGuard } from './auth/auth.guard';
import { IosAddHomescreenComponent } from './components/ios-add-homescreen/ios-add-homescreen.component';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';

export const createTranslateloader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
  declarations: [AppComponent, NotificationsComponent, IosAddHomescreenComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(environment.config),
    AppRoutingModule,
    HttpClientModule,
    ImagePageModule,
    CartPageModule,
    ThankYouPageModule,
    UnderEighteenPageModule,
    CartExpiredPageModule,
    OrderRefusedPageModule,
    PaymentFailedPageModule,
    QRScannerPageModule,
    RestaurantInfoPageModule,
    LocationPageModule,
    SearchFilterPageModule,
    RequestTableNumberPageModule,
    MenuItemWithOptionsPageModule,
    IonicStorageModule.forRoot({
      name: '__presto',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateloader,
        deps: [HttpClient]
      }
    }),
    PipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SocialLoginModule
  ],
  entryComponents: [NotificationsComponent, QRScannerPage, IosAddHomescreenComponent],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' },
    TranslateProvider,
    RestaurantService,
    SettingsService,
    LocalNotifications,
    InAppBrowser,
    Geolocation,
    NativeGeocoder,
    AnalyticsService,
    AuthGuard,
    WonderPush,
    Network,
    GooglePlus,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '174108942951-6d8ts5un6kk5obt8edvvq80asmubmi5q.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.social.FB_PROVIDER_ID)
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
