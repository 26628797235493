import { Table } from './table.model';

export class Restaurant {
    constructor(
        public city: string,
        public currency: string,
        public email: string,
        public gpsLatitude: number,
        public gpsLongitude: number,
        public houseNumber: number,
        public companyId: number,
        public id: number,
        public name: string,
        public phone: string,
        public street: string,
        public websiteLink: string,
        public menu: [],
        public workflow_type_code: string,
        public tables?: Table[],
        public paymentProvider?: {id: number, name: string},
        public paymentSettings?: any,
        public settings?: {
            cart_timeout: number
            charge_under_amount: number
            extra_charge_amount: number
            fee_multiplier: number
            service_fee: number
        }
    ) {
    }
}
