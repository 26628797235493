import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MenuItemWithOptionsPage } from './menu-item-with-options.page';
import { OptionsGroupComponent } from './options-group/options-group.component'

const routes: Routes = [
  {
    path: '',
    component: MenuItemWithOptionsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
 //   FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MenuItemWithOptionsPage, OptionsGroupComponent],
  entryComponents: [MenuItemWithOptionsPage]
})
export class MenuItemWithOptionsPageModule {}
