import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'home-location', loadChildren: () => import('./pages/home-location/home-location.module').then(m => m.HomeLocationPageModule), canLoad: [AuthGuard] },
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule), canLoad: [AuthGuard]  },
  { path: 'support', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule), canLoad: [AuthGuard]  },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule), canLoad: [AuthGuard] },
  //{ path: 'edit-profile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule), canLoad: [AuthGuard] },
  { path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule), canLoad: [AuthGuard] },
  { path: 'message/:id', loadChildren: () => import('./pages/message/message.module').then(m => m.MessagePageModule), canLoad: [AuthGuard] },
  // { path: 'restaurant-search', loadChildren: () => import('./pages/restaurant-search/restaurant-search.module').then(m => m.RestaurantSearchPageModule), canLoad: [AuthGuard] },
  { path: 'search-filter', loadChildren: () => import('./pages/modal/search-filter/search-filter.module').then(m => m.SearchFilterPageModule), canLoad: [AuthGuard] },
  //{ path: 'nearby', loadChildren: () => import('./pages/nearby/nearby.module').then(m => m.NearbyPageModule), canLoad: [AuthGuard] },
  { path: 'bycategory', loadChildren: () => import('./pages/bycategory/bycategory.module').then(m => m.BycategoryPageModule), canLoad: [AuthGuard] },
  { path: 'restaurant-list/:cat', loadChildren: () => import('./pages/restaurant-list/restaurant-list.module').then(m => m.RestaurantListPageModule), canLoad: [AuthGuard] },
  //{ path: 'restaurant/:restaurantId', loadChildren: () => import('./pages/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailPageModule), canLoad: [AuthGuard] },
  { path: 'dish-list', loadChildren: () => import('./pages/dish-list/dish-list.module').then(m => m.DishListPageModule), canLoad: [AuthGuard] },
  { path: 'dish-detail/:id', loadChildren: () => import('./pages/dish-detail/dish-detail.module').then(m => m.DishDetailPageModule), canLoad: [AuthGuard] },
  { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule), canLoad: [AuthGuard] },
  { path: 'favorites', loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule), canLoad: [AuthGuard] },
  { path: 'latest-orders', loadChildren: () => import('./pages/latest-orders/latest-orders.module').then(m => m.LatestOrdersPageModule), canLoad: [AuthGuard] },
  { path: 'cart', loadChildren: () => import('./pages/modal/cart/cart.module').then(m => m.CartPageModule), canLoad: [AuthGuard] },
  { path: 'extras', loadChildren: () => import('./pages/extras/extras.module').then(m => m.ExtrasPageModule), canLoad: [AuthGuard] },
  //{ path: 'extras/profile-one', loadChildren: () => import('./pages/extras/profile-one/profile-one.module').then(m => m.ProfileOnePageModule), canLoad: [AuthGuard] },
  //{ path: 'extras/profile-two', loadChildren: () => import('./pages/extras/profile-two/profile-two.module').then(m => m.ProfileTwoPageModule), canLoad: [AuthGuard] },
  //{ path: 'extras/timeline', loadChildren: () => import('./pages/extras/timeline/timeline.module').then(m => m.TimelinePageModule), canLoad: [AuthGuard] },
  //{ path: 'extras/authentication', loadChildren: () => import('./pages/extras/authentication/authentication.module').then(m => m.AuthenticationPageModule), canLoad: [AuthGuard] },
  //{ path: 'extras/popupmenu', loadChildren: () => import('./pages/extras/popupmenu/popupmenu.module').then(m => m.PopupmenuPageModule), canLoad: [AuthGuard] },
  //{ path: 'extras/charts', loadChildren: () => import('./pages/extras/charts/charts.module').then(m => m.ChartsPageModule), canLoad: [AuthGuard] },
  //{ path: 'extras/post', loadChildren: () => import('./pages/extras/post/post.module').then(m => m.PostPageModule), canLoad: [AuthGuard] },
  {
    path: '',
    children: [
      {
        path: 'places',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/restaurant-search/restaurant-search.module').then(mod => mod.RestaurantSearchPageModule), 
            canLoad: [AuthGuard]
          },
          { 
            path: 'restaurant/:restaurantId', 
            loadChildren: () => import('./pages/restaurant-detail/restaurant-detail.module').then(m => m.RestaurantDetailPageModule), 
            canLoad: [AuthGuard] 
          }
        ]
      },
      // {
      //   path: 'nearby',
      //   loadChildren: () => import('./pages/nearby/nearby.module').then(mod => mod.NearbyPageModule),
      //   canLoad: [AuthGuard]
      // },
      {
        path: 'latest-orders',
        loadChildren: () => import('./pages/latest-orders/latest-orders.module').then(mod => mod.LatestOrdersPageModule),
        canLoad: [AuthGuard]
      },
      {
        path: 'qr-code',
        loadChildren: () => import('./pages/modal/qr-scanner/qr-scanner.module').then(mod => mod.QRScannerPageModule), 
        canLoad: [AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(mod => mod.EditProfilePageModule), 
        canLoad: [AuthGuard]
      },
      {
        path: '',
        redirectTo: '/places',
        pathMatch: 'full'
      }
    ]
  },
  { path: '', redirectTo: '/places', pathMatch: 'full', canLoad: [AuthGuard] },
  { path: 'request-table-number', loadChildren: () => import('./pages/modal/table/request-table-number/request-table-number.module').then(m => m.RequestTableNumberPageModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
