import { Injectable } from '@angular/core';
declare var gtag;


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

    constructor() { }

    setTracker(tracker) {
        if ( !localStorage.getItem('ga:clientId') ) {
            localStorage.setItem( 'ga:clientId', tracker.get('clientId') );
        }
    }

    startTrackerWithId(id) {
        gtag('config', 'G-Y5NSYMGNWL');
    }

    trackView(pageUrl: string, screenName: string) {
        gtag('event', {
            page_title: screenName,
            page_location: pageUrl
        });
    }

    trackEvent(category, action, label?, value?, restaurant_name?) {

        const data = {
            'event_category': category
        };
        if (label) {
            Object.assign(data, {'event_label': label});
        }
        if (value) {
            Object.assign(data, {'value': value});
        }
        if (restaurant_name) {
            Object.assign(data, {'restaurant_name': restaurant_name});
        }
        gtag('event', action, data);
    }
}

