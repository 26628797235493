export const environment = {
  production: false, // set TRUE before you build and release a prod version.
  // Set your app configurations here.
  // For the list of config options, please refer to https://ionicframework.com/docs/api/config/Config/
  config: {
    autoFocusAssist: false,
    menuType: 'overlay'
  },
  domain: 'https://api-dev.prestoapp.eu',
  api: {
    url: 'https://api-dev.prestoapp.eu/api'
  },
  payment: {
    payU: {
      auth: {
        url: 'https://secure.snd.payu.com/pl/standard/user/oauth/authorize',
        grant_type: 'client_credentials',
        client_id: 370187,
        client_secret: '613fca5c978a184368936b4f3e64da12'
      }
    }
  },
  social: {
    FB_PROVIDER_ID: '4945965088750048'
  },
  // Set language to use.
  language: 'hu',
  // Loading Configuration.
  // Please refer to the official Loading documentation here: https://ionicframework.com/docs/api/components/loading/LoadingController/
  loading: {
    spinner: 'circles'
  },
  // Toast Configuration.
  // Please refer to the official Toast documentation here: https://ionicframework.com/docs/api/components/toast/ToastController/
  toast: {
    position: 'bottom' // Position of Toast, top, middle, or bottom.
  },
  toastDuration: 3000, // Duration (in milliseconds) of how long toast messages should show before they are hidden.

  maps: {
    defaultLat: 47.5096208,
    defaultLng: 19.0565969,
    key: 'AIzaSyC5a0_yMy-hF22IVwc1eGgJAV59OpUCWHc'
  },
  analytics: {
    tracker: 'G-Y5NSYMGNWL'
  }
};
