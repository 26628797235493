import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ios-add-homescreen',
  templateUrl: './ios-add-homescreen.component.html',
  styleUrls: ['./ios-add-homescreen.component.scss'],
})
export class IosAddHomescreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  @Output()
  oncancelIosAddHome: EventEmitter<any> = new EventEmitter<any>();

  cancelEmmiter(event) {
    this.oncancelIosAddHome.emit();
  }
}
