import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OrdersService {
  orderCounter = 0;
  orders: Array<object> = [];

  constructor(
    private http: HttpClient
  ) {}

  saveOrder(order, total, orderNumber) {
    this.orderCounter = this.orderCounter + 1;
    this.orders.push({id: this.orderCounter, order: order, total: total, onumber: orderNumber});
    return Promise.resolve();
  }

  getOrders(userId): Observable<any> {
    
    /*
      [
        order,
        order,
        {
          order_items: [
            order_item,
            {
              menu_item: {
                menu_item_names: [
                  {
                    language_id": 1,
                    "name": "Coca Cola 0.2l",
                  }
                ]
              },
              
            }
          ]
        },
        order
      ]
     */

    const url = `${environment.api.url}/user/${userId}/orderhistory`;
    return this.http.get(url);
  }
}
