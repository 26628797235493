import { Injectable } from '@angular/core';
//import restaurants from './mock-restaurants';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, map, take } from 'rxjs/operators';
import { Restaurant } from '../../models/restaurant.model';
import * as moment from 'moment';
import { NavController } from '@ionic/angular';
import { from, Observable, BehaviorSubject } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { RootCategories } from '../../models/root-categories.model';
import { TranslateService } from '@ngx-translate/core';
import { Table } from '../../models/table.model';

interface VenueRestaurantData {
  city: string;
  currency: string;
  email: string;
  gpsLatitude: number;
  gpsLongitude: number;
  houseNumber: number;
  idCompany: number;
  id: number;
  name: string;
  phone: string;
  street: string;
  websiteLink: string;
}

interface VenueData {
  restaurant: VenueRestaurantData;
  openTimes: {
    startHour: string;
    endHour: string;
    idOpenTimes: number;
    weekday: string;
  }[];
  tables: {
    restaurant_id: number;
    id: number;
    name: string;
    number: number;
  }[];

  specialOfferWithRequirementsList: {
    specialOffer: {
      discountFixAmount: number;
      discountPercentage: number;
      giftProductId: number;
      restaurant_id: number;
      idSpecialOffer: number;
    };
    specialOfferName: string;
    specialOfferRequirements: string;
  }[];
  menu: any;
}

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  private _selectedPlace: Restaurant;
  private _rootCategories = new BehaviorSubject<RootCategories[]>([]);

  favoriteCounter = 0;
  favorites: Array<any> = [];
  restaurants: Array<any> [];
  languageId = 1;

  constructor(
    private http: HttpClient,
    public navCtrl: NavController,
    private translateService: TranslateService
    ) {}

  createRestaurantObject(data) {

    const tables: Table[] = [];

    if (typeof data.tables !== 'undefined') {
      data.tables.forEach((table) => {
        const t = new Table(table.id, table.restaurant_id, table.number, table.name);
        tables.push(t);
      });
    }

    return new Restaurant(
      data.city,
      data.currency,
      data.email,
      data.gpsLatitude,
      data.gpsLongitude,
      data.houseNumber,
      data.company_id,
      data.id,
      data.name,
      data.phone,
      data.street,
      data.websiteLink,
      data.menu,
      data.workflow_type ? data.workflow_type.code : 1,
      tables,
      (data.payment_provider ? data.payment_provider : null),
      (data.payment_settings ? data.payment_settings : null),
      (data.settings ? data.settings : null),
      );
  }

  get list(): Observable<Restaurant[]> {

    return this.http.get<Restaurant[]>(`${environment.api.url}/restaurant/index`);

    return new Observable(observer => {
      
      this.storedVenueList.pipe(take(1),
        map(list => {
          if (list) {
            observer.next(list);
          } else {
            this.http.get<Restaurant[]>(`${environment.api.url}/restaurant/index`).pipe(
              tap(res => {
                if (res) {
                  const data = {
                    restaurants: res,
                    timeOfSet: moment().format('YYYY-MM-DD HH:mm:ss')
                  };
                  Plugins.Storage.set({key: 'venue-list', value: JSON.stringify(data)});
                }
            })).subscribe( r => {
              observer.next(r);
            });
          }
        })
      ).subscribe();
    });
  }

  get storedVenueList(): Observable<Restaurant[]> {
    return from(Plugins.Storage.get({key: 'venue-list'})).pipe(
      map(storedData => {
      const parsedData = JSON.parse(storedData.value);
      return parsedData ? parsedData.restaurants : null;
    }));
  }

  get selectedPlace(): Observable<Restaurant> {

    return from(Plugins.Storage.get({key: 'selected-venue'})).pipe(
        map(storedData => {
          if (storedData) {
            const parsedData = JSON.parse(storedData.value);

            if (parsedData && parsedData.venue && parsedData.timeOfSet) {
              const timeOfSet = moment(parsedData.timeOfSet);
              const diff = moment().diff(timeOfSet, 'hours');
  
              if (diff < 4) {
                return parsedData.venue;
              }
            }
           
            return null;
          }
        }),
        tap(venue => {
          if (venue) {
            const restaurant: Restaurant = new Restaurant(
              venue.city,
              venue.currency,
              venue.email,
              venue.gpsLatitude,
              venue.gpsLongitude,
              venue.houseNumber,
              venue.idCompany,
              venue.id,
              venue.name,
              venue.phone,
              venue.street,
              venue.websiteLink,
              venue.menu,
              venue.workflow_type_code,
              venue.tables,
              (venue.paymentProvider ? venue.paymentProvider : null),
              (venue.paymentSettings ? venue.paymentSettings : null),
              (venue.settings ? venue.settings : null),
            );
            this.setSelectedPlace(restaurant);
            return restaurant;

          }
          return null;
        }),
        map(restaurant => {
          return restaurant;
        })
      );
  }

  setSelectedPlace(venue: Restaurant) {
    this._selectedPlace = venue;

    const data = {
      venue: this._selectedPlace,
      timeOfSet: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    return Plugins.Storage.set({key: 'selected-venue', value: JSON.stringify(data)});

    // return this.storage.set('selected-venue', {
    //   venue: this._selectedPlace,
    //   timeOfSet: moment().format('YYYY-MM-DD HH:mm:ss')
    // });

  }

  // used by nearby page
  findAll() {
    return Promise.resolve(this.restaurants);
  }

  getRestaurants() {
    return this.restaurants;
  }
  getItem(id) {
    // for (let i = 0; i < this.restaurants.length; i++) {
    //   if (this.restaurants[i].id === parseInt(id)) {
    //     return this.restaurants[i];
    //   }
    // }
    return null;
  }

  findByName(term: string) {
    return this.http.get<VenueRestaurantData>(environment.api.url + '/restaurant/search', {
      params: { nameStr: term }
    }).pipe(map(resData => {
      const venues = [];

      for (const key in resData) {        
        if (resData.hasOwnProperty(key)) {          
          const venue = this.createRestaurantObject(resData[key]);
          venues.push(venue);
        }
      }      

      return venues;
    }));
  }

  getFavorites() {
    return Promise.resolve(this.favorites);
  }

  favorite(restaurant) {
    this.favoriteCounter = this.favoriteCounter + 1;
    this.favorites.push({id: this.favoriteCounter, restaurant: restaurant});
    return Promise.resolve();
  }

  unfavorite(favorite) {
    const index = this.favorites.indexOf(favorite);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
    return Promise.resolve();
  }

  getPlaceById(id: number, languageId: number = 1) {
    return this.http.get<VenueData>(`${environment.api.url}/restaurant/${id}/alldata/language/${languageId}`)
    .pipe(map(resData => {
      return this.createRestaurantObject(resData);
    }));

  }

  get rootCategories() {
    return this._rootCategories.asObservable();
  }

  getRootMenu() {
    const returnMenu = [];

    this._selectedPlace.menu.forEach((element: any) => {

      returnMenu.push({
        name: element.menu_name,
        id: element.id
      });
    });

    return returnMenu;
  }

  getMenuItems(menuId: number) {
    return new Promise((resolve, reject) => {

      this.selectedPlace.subscribe((value: any) => {

        const menuRoot: any = value.menu;
        const menus = menuRoot.filter(m => parseInt(m.id, 10) === +menuId);

        resolve(menus);
      });

    });
  }

  getMenuItemById(id: number) {

    return new Promise((resolve, reject) => {

      this.selectedPlace.subscribe(function(venue: any) {

        if (!venue) {
          resolve(null);
        }

        if (!venue) {
          resolve([]);
        } else {
          let menu;

          venue.menu.forEach((element: any) => {
            element.menu_items.forEach((item) => {
              if (item.id === +id) {
                menu = {
                  id: item.id,
                  price: item.price,
                 // picture: "assets/img/dishes/dish033.jpg",
                  images: item.image ? [item.image] : ['/assets/img/dishes/no-img.jpg'],
                  name: item.menu_item_names[0].name,
                  description: item.menu_item_names[0].description,
                  ingredients: ['marha']
                };
                resolve(menu);
              }
            });
          });
        }
      });
    });
  }

  formatAddress(restaurant: Restaurant) {
    const lang = this.translateService.currentLang;
    if (lang === 'hu') {
      return restaurant.city + ', ' + restaurant.street + ' ' + restaurant.houseNumber;
    } else {
      return restaurant.houseNumber + restaurant.street + ', ' + restaurant.city + restaurant;
    }
  }
}
