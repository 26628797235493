const categories: Array<any> = [
    {
        id: 1,
        name: 'Pizza',
        picture: 'assets/img/restaurants/restaurant01.jpg',
        quantity: 41
    },
    {
        id: 2,
        name: 'Pasta',
        picture: 'assets/img/restaurants/restaurant02.jpg',
        quantity: 64
    },
    {
        id: 3,
        name: 'Variable',
        picture: 'assets/img/restaurants/restaurant03.jpg',
        quantity: 64
    },
    {
        id: 4,
        name: 'Barbecue',
        picture: 'assets/img/restaurants/restaurant04.jpg',
        quantity: 28
    }
];

export default categories;
