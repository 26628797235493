import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';

import { LoadingController, Platform } from '@ionic/angular';
import jsQR from 'jsqr';
import { TableService } from '../../../providers/table/table.service';
import { CartService, RestaurantService } from '../../../providers';
import { Table } from '../../../models/table.model';
import { AnalyticsService } from '../../../providers/analytics/analytics.service';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.page.html',
  styleUrls: ['./qr-scanner.page.scss'],
})

export class QRScannerPage implements AfterViewInit {
  @ViewChild('video') video: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('fileinput') fileinput: ElementRef;

  canvasElement: any;
  videoElement: any;
  canvasContext: any;
  scanActive = false;
  scanResult = null;
  loading: HTMLIonLoadingElement = null;

  constructor(
    private loadingCtrl: LoadingController,
    private plt: Platform,
    public tableService: TableService,
    public restaurantService: RestaurantService,
    public navCtrl: NavController,
    private analyticsService: AnalyticsService,
    private cartService: CartService,
  ) {
    const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator['standalone'];

    if (this.plt.is('ios') && isInStandaloneMode()) {
      //console.log('I am a an iOS PWA!');
      // E.g. hide the scan functionality!
    }
  }

  ngAfterViewInit() {
    this.canvasElement = this.canvas.nativeElement;
    this.canvasContext = this.canvasElement.getContext('2d');
    this.videoElement = this.video.nativeElement;
  }

  async stopScan() {
    this.scanActive = false;
    this.videoElement.srcObject.getTracks().forEach(function(track) {
      track.stop();
    });
    this.analyticsService.trackEvent('QR', 'Stop scan');    
  }

  ionViewDidEnter() {
    this.startScan();
  }

  ionViewWillLeave() {
    this.stopScan();
  }

  async startScan() {
    this.analyticsService.trackEvent('QR', 'Start scan');

    // Not working on iOS standalone mode!
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' }
    });

    this.videoElement.srcObject = stream;
    // Required for Safari
    this.videoElement.setAttribute('playsinline', true);

    this.loading = await this.loadingCtrl.create({});
    await this.loading.present();

    this.videoElement.play();
    requestAnimationFrame(this.scan.bind(this));
  }

  async scan() {
    if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
        this.scanActive = true;
      }

      this.canvasElement.height = this.videoElement.videoHeight;
      this.canvasElement.width = this.videoElement.videoWidth;

      this.canvasContext.drawImage(
        this.videoElement,
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const imageData = this.canvasContext.getImageData(
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert'
      });

      if (code) {
        this.scanActive = false;
        this.scanResult = code.data;

        const tableId = this.getTableIdFromURL(code.data);

        if (tableId) {
          this.tableService.getRestaurantByTable(tableId).subscribe((data: any) => {
            const venue = this.restaurantService.createRestaurantObject(data);
            this.analyticsService.trackEvent('QR', 'QR Code scanned', venue.name, tableId);
            this.restaurantService.setSelectedPlace(venue).then(() => {

              const tableData = venue.tables.filter((t: Table) => {
                return +tableId === +t.id;
              });
              const table = new Table(tableData[0].id, tableData[0].restaurant_id, tableData[0].number, tableData[0].name);
              this.cartService.setSelectedTable(table);

              this.navCtrl.navigateForward(`/places/restaurant/${venue.id}`);
            });
          });
        } else {
          alert('Az étterem nem található.');
          this.startScan();
        }

      } else {
        if (this.scanActive) {
          requestAnimationFrame(this.scan.bind(this));
        }
      }
    } else {
      requestAnimationFrame(this.scan.bind(this));
    }
  }

  //     this.getTableIdFromURL('http://sabisoftwareltd.com/presto/qr.php?tableId=10');
  getTableIdFromURL(url: string): number {
    const params = url.slice(url.indexOf('?') + 1).split('&').reduce( (acc, query) => {
        const parts = query.split('=');
        acc[parts[0]] = parts[1];
        return acc;
    }, {});

    if (params.hasOwnProperty('tableId')) {
      return params['tableId'];
    }

    return null;
  }

  captureImage() {
    this.fileinput.nativeElement.click();
  }

  handleFile(files: FileList) {
    const file = files.item(0);

    const img = new Image();
    img.onload = () => {
      this.canvasContext.drawImage(img, 0, 0, this.canvasElement.width, this.canvasElement.height);
      const imageData = this.canvasContext.getImageData(
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert'
      });

      if (code) {
        this.scanResult = code.data;
        // DO SOMETHING HERE WITH THE DATA
      }
    };
    img.src = URL.createObjectURL(file);
  }
}
