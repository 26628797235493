export class User {
    constructor(
        public id: number,
        public email: string,
        public name: string,
        public profile_image_url: string,
        public isOver18: boolean,
        public isOver18ConfirmDate: string,
        public city: string,
        private _token: string,
        public tokenExpirationDate: Date
    ) {}

    get token() {
        if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
            return null;
        }
        return this._token;
    }

    get tokenDuration() {
        if (!this.token) {
            return 0;
        }
        return this.tokenExpirationDate.getTime() - new Date().getTime();
    }
}
