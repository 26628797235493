import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { CartExpiredPage } from './cart-expired.page';

const routes: Routes = [
  {
    path: '',
    component: CartExpiredPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forRoot(),
    RouterModule.forChild(routes)
  ],
  exports: [TranslateModule],
  declarations: [CartExpiredPage],
  entryComponents: [CartExpiredPage]
})
export class CartExpiredPageModule {}
