import { Component, Input } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PayUService } from '../../../providers/payment/payu.service';

import {
  DishService,
  CartService
} from '../../../providers';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cart',
  templateUrl: './order-refused.page.html',
  styleUrls: ['./order-refused.page.scss'],
})

export class OrderRefusedPage {

  @Input() data: any;
  @Input() payment: any;
  @Input() orderHeadId: any;

  public message = '';

  constructor(
    public navCtrl: NavController,
    public dishService: DishService,
    public cartService: CartService,
    public route: Router,
    private modalCtrl: ModalController,
    private http: HttpClient
  ) {} 
 
  ionViewWillEnter() {}

  closeModal() { 
    this.http.post(`${environment.api.url}/orderhead/setstatus`, {
      orderHeadId: this.orderHeadId,
      statusCode: 'EDIT',
      emptyMessage: true
    }).subscribe(r => {
      this.payment.status.next('WAITING_FOR_USER_INPUT');
      this.modalCtrl.dismiss();
    });
  }
}
