import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { CartService } from '../../../../providers/cart/cart.service';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {

  @Output()
  addToCart: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  increaseOrderItemQuantity: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  decreaseOrderItemQuantity: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  orderItem: any = null; 

  @Input()
  quantity = null;

  constructor(
    private cartService: CartService
  ) { }

  ngOnInit() {} 

  addItemToCart(item) {
    this.addToCart.emit(item);
  }

  increaseItemQuantity(orderItem) {
    console.log(orderItem);
    this.increaseOrderItemQuantity.emit(orderItem);
  }

  decreaseItemQuantity(orderItem) {
    console.log(orderItem);
    this.decreaseOrderItemQuantity.emit(orderItem);
  }

}


