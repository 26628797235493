import { Component, Input, ViewChild } from '@angular/core';
import { NavController, ModalController, IonItemSliding } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import {
  DishService,
  CartService,
  RestaurantService
} from '../../../providers';

import { TableService } from '../../../providers/table/table.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Restaurant } from '../../../models/restaurant.model';

@Component({
  selector: 'app-cart',
  templateUrl: './thank-you.page.html',
  styleUrls: ['./thank-you.page.scss'],
})

export class ThankYouPage {

  @Input() status: BehaviorSubject<string>; 
  @Input() message: any;

  //public status = new BehaviorSubject<string>(null);   

  constructor(
    public navCtrl: NavController,
    public dishService: DishService,
    public cartService: CartService,
    public route: Router,
    private modalCtrl: ModalController,
  ) {}

  ionViewWillEnter() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
