import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Restaurant } from '../../../models/restaurant.model';
import { StripeService } from '../../../providers/payment/stripe.service';

@Component({
  selector: 'app-cart-expired',
  templateUrl: './cart-expired.page.html',
  styleUrls: ['./cart-expired.page.scss'],
})

export class CartExpiredPage implements OnInit{

  @Input() restaurant: Restaurant;
  @Input() payment: any;

  constructor(
    public navCtrl: NavController,
    public route: Router,
    private modalCtrl: ModalController,
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    // Configure the TranslateService
		this.translateService.setDefaultLang('en');
		this.translateService.use('en');
  }
  ionViewWillEnter() {}

  closeModal() {
    this.navCtrl.navigateForward('/places');
    this.payment.status.next('WAITING_FOR_USER_INPUT');
    this.modalCtrl.dismiss();
  }
}
