import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'options-group',
  templateUrl: './options-group.component.html',
  styleUrls: ['./options-group.component.scss'],
})
export class OptionsGroupComponent implements OnInit {

  @Input() groups: any = null; 
  @Input() formGroup: FormGroup;

  constructor() { }

  ngOnInit() {}

}
