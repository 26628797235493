import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {from, Observable} from 'rxjs';
import {IonInput, LoadingController, ModalController, Platform} from '@ionic/angular';
import {Keyboard} from '@ionic-native/keyboard/ngx';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Table} from '../../../../models/table.model';
import {TranslateService} from '@ngx-translate/core';
import {TableData} from '../../../../interfaces/table-data.interface';
import {Plugins} from '@capacitor/core';

@Component({
    selector: 'app-request-table-number',
    templateUrl: './request-table-number.page.html',
    styleUrls: ['./request-table-number.page.scss'],
    providers: [Keyboard]
})
export class RequestTableNumberPage implements OnInit, OnDestroy {

    @ViewChild('tableNumber', {static: true}) inputElement: IonInput;

    public loading = true;
    public validatingInprogress = false;
    public errorMessage: string;
    public displayErrorMessage = false;

    @Input() restaurantId: number;
    @Input() tableObservable: Observable<Table>;

    constructor(//  public restaurantService: RestaurantService,
                public router: Router,
                public keyboard: Keyboard,
                public plaftorm: Platform,
                private modalCtrl: ModalController,
                private http: HttpClient,
                private loadingCtrl: LoadingController,
                private translateService: TranslateService) {
    }

    ngOnInit() {
    }

    ionViewWillEnter() {
        this.tableObservable.subscribe(table => {

            if (table) {
                this.inputElement.value = table.number;
            }
        });
    }

    ionViewDidEnter() {
        setTimeout(() => {
            this.inputElement.setFocus();

            if (this.plaftorm.is('mobile')) {
                this.keyboard.show();
            }
        }, 400);
    }

    onButtonClicked() {
        if (this.inputElement.value !== '') {
            this.errorMessage = '';
            this.validateTable(+this.restaurantId, +this.inputElement.value).subscribe(table => {
                if (table) {
                    this.errorMessage = '';
                    const tableObject = new Table(
                        table.id,
                        table.restaurant_id,
                        table.number,
                        table.name
                    );

                    this.modalCtrl.dismiss(tableObject);
                } else {
                    this.displayErrorMessage = true;
                    this.errorMessage = this.translateService.instant('app.modal.tablenumber.generalerrormessage');
                }
            });
        }
    }

    numberOnlyValidation(event) {
        const pattern = /[0-9]/; // without ., for integer only
        let inputChar = String.fromCharCode(event.which ? event.which : event.keyCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
            return false;
        }
        return true;
    }

    validateTable(restaurantId, tableId): Observable<TableData> {
        return new Observable(observer => {

            const filterTable = function (array: any[], needle: number) {
                const selected = array.filter(item => {
                    return +needle === +item.number;
                });
                return selected.length ? selected[0] : false;
            };

            this.http.get<Table[]>(`${environment.api.url}/restaurant/${restaurantId}/table`).subscribe(tables => {
                if (!tableId) {
                    this.retriveTable.subscribe(storedTable => {
                        if (storedTable && storedTable.table) {
                            observer.next(filterTable(tables, storedTable.table.number));
                        } else {
                            observer.next(null);
                        }
                    });
                } else {
                    observer.next(filterTable(tables, tableId));
                }
            });
        });
    }

    get retriveTable() {
        return from(Plugins.Storage.get({key: 'selected-table'})).pipe(
            map(storedData => {
                if (storedData) {
                    const parsedData = JSON.parse(storedData.value);
                    return parsedData ? parsedData : null;
                }
            })
        );
    }

    ngOnDestroy() {
    }
}
