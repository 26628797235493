import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap, map, switchMap, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, from, of } from 'rxjs';
import { User } from '../models/user.model';
import { Plugins } from '@capacitor/core';
import { GooglePlus } from '@ionic-native/google-plus/ngx';

import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

declare global {
  interface Window {
    WonderPush:any;
  } 
}

interface AuthResponseData {
  success: boolean;
  errorMessage: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _user = new BehaviorSubject<User>(null);
  private activeLogoutTimer: any;

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        return !!user.token;
      } else {
        return false;
      }
     }));
  }

  get userId () {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        return user.id;
      } else {
        return null;
      }
    }));
  }

  get user () {
    return this._user.asObservable().pipe(map(user => {
      if (user) {
        return user;
      } else {
        return null;
      }
    }));
  }

  constructor(
    private http: HttpClient, 
    private googlePlus: GooglePlus,
    private socialAuthService: SocialAuthService,
    private platform: Platform,
    private router: Router,
    ) {
    }

  autoLogin() {
    return from(Plugins.Storage.get({key: 'authData'})).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          id: number,
          token: string;
          name: string;
          profile_image_url: string;
          isOver18: boolean;
          over18ConfirmDate: string;
          city: string,
          tokenExpirationDate: string;
          email: string;
        };
        const expirationTime = new Date(parsedData.tokenExpirationDate);
        if (expirationTime <= new Date()) {
          return null;
        }

        const user = new User(
          parsedData.id,
          parsedData.email,
          parsedData.name,
          parsedData.profile_image_url,
          parsedData.isOver18,
          parsedData.over18ConfirmDate,
          parsedData.city,
          parsedData.token,
          expirationTime
        );

        return user;
    }),
    tap(user => {
      if (user) {
        this._user.next(user);
        this.autoLogout(user.tokenDuration);
      }
    }),
    tap(user => {

      if (user) {
        window.WonderPush.push(["init", {
            webKey: "795184ef159d4e2816b1f7607c7b530d926e899c446aff501580cfedc2b65601",
            userId: user.email,
        }]);        
      }
    }),
    map(user => {
      return !!user;
    }));
  }

  autoLogout(duration: number) {

    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }

    this.activeLogoutTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  signup(fullName: string, email: string, password: string, isOver18: boolean) {
    this.logout();
    const data = {
      name: fullName,
      email: email,
      password: password,
      password_confirmation: password,
      is_over_18: isOver18
      // to be removed later
      // city: 'Budapest',
      // phone: '+447438341925',
      // defaultLanguage: 'hun'
    };

    return this.http.post<AuthResponseData>(`${environment.api.url}/register`, data);
  }

  login (email: string, password: string) {
    return this.http.post(`${environment.api.url}/login`, {email: email, password: password}, {
      observe: 'response',
    }).pipe(tap(resp => {
      this.afterLogin(resp);
    }));
  }

  socialLogin(data)
  {
    return this.http.post(`${environment.api.url}/sociallogin`, data, {
      observe: 'response',
    }).pipe(tap(resp => {
      this.afterLogin(resp);
    }));
  }

  private afterLogin(resp)
  {
    const expirationDate = new Date(
      // 3600sec * 24h * 24days * 1000toMilisec
      // setTimeOut limitation: 2147483647 so I use 24days
      new Date().getTime() + +(3600 * 24 * 24 * 1000)
    );

    const body: any = resp.body;
    const token = resp.headers.get('Authorization');

    const user = new User(
      body.guestId,
      body.email,
      body.name,
      body.profile_image_url,
      body.is_over_18,
      body.over_18_confirm_date, 
      body.city,
      token,
      expirationDate,
    );

    window.WonderPush.push(["init", {
      webKey: "795184ef159d4e2816b1f7607c7b530d926e899c446aff501580cfedc2b65601",
      userId: user.email,
  }]);

    this._user.next(user);
    this.autoLogout(user.tokenDuration);
    this.storeAuthData(user.id, token, body.name, body.profile_image_url, body.is_over_18, body.over_18_confirm_date, body.city, expirationDate.toISOString(), body.email);
  }

  logout () {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    this._user.next(null);
    Plugins.Storage.remove({key: 'authData'});
    Plugins.Storage.remove({key: 'venue-list'}); 
  }

  private storeAuthData(id: number, token: string, name: string, profile_image_url: string, isOver18: boolean, over18ConfirmDate: string, city: string,  tokenExpirationDate: string, email: string) {
    const data = {
      id: id,
      token: token,
      name: name,
      profile_image_url: profile_image_url,
      isOver18: isOver18,
      over18ConfirmDate: over18ConfirmDate,
      city: city,
      tokenExpirationDate: tokenExpirationDate,
      email: email
    };
    Plugins.Storage.set({key: 'authData', value: JSON.stringify(data)});
  }

  public getuserFromStorage()
  {
    return from(Plugins.Storage.get({key: 'authData'})).pipe(
      switchMap(storedData => {

        const parsedData = JSON.parse(storedData.value) as {
          id: number,
          token: string;
          name: string;
          profile_image_url: string;
          isOver18: boolean;
          over18ConfirmDate: string;
          city: string,
          tokenExpirationDate: string; 
          email: string;
        };

        const expirationTime = new Date(parsedData.tokenExpirationDate);
        const user = new User(
          parsedData.id,
          parsedData.email,
          parsedData.name,
          parsedData.profile_image_url,
          parsedData.isOver18,
          parsedData.over18ConfirmDate,
          parsedData.city,
          parsedData.token,
          expirationTime
        );

        return of(user);

      })
    );
  }

  public setOverEightTeenConfirmDate(date)
  {
    this.getuserFromStorage().pipe(
      tap(user => {
        const now = new Date();
        const dateTimeString = now.toISOString().split('T')[0] + ' ' + now.toISOString().split('T')[1].split('.')[0];

        this.storeAuthData(
          user.id, user.token, user.name, user.profile_image_url, user.isOver18, dateTimeString, user.city, user.tokenExpirationDate.toISOString(), user.email
        );

        user.isOver18ConfirmDate = user.tokenExpirationDate.toISOString();
        this._user.next(user);
      }),
    ).subscribe();
  }

  public googleLogin()
  {
    if (this.platform.is('cordova')) {
      this.googlePlus.login({})
      .then(result => {

        const data = {
          provider: "GOOGLE",
          firstName: result.givenName,
          lastName: result.familyName,
          email: result.email,
          id: result.userId,
          photoUrl: result.imageUrl
        };

        this.socialLogin(data).subscribe(() => {
          this.router.navigateByUrl(`/places`);
        });

        // probably we should not redirect from service
      })
      .catch(err => {
        alert(`Error ${JSON.stringify(err)}`);
      });

    } else { // not cordova
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }
  }

  facebookLogin() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy() {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }
  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );
  }
}
