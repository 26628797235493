import { Component, Input } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import {
  DishService,
  CartService
} from '../../../providers';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { PayUService } from '../../../providers/payment/payu.service';
import { StripeService } from '../../../providers/payment/stripe.service';

@Component({
  selector: 'app-cart',
  templateUrl: './payment-failed.page.html',
  styleUrls: ['./payment-failed.page.scss'],
})

export class PaymentFailedPage {

  @Input() orderHeadId$: Observable<number>;
  @Input() payment: PayUService | StripeService;
  @Input() result: any;

  constructor(
    public navCtrl: NavController,
    public dishService: DishService,
    public cartService: CartService,
    public route: Router,
    private modalCtrl: ModalController,
    private http: HttpClient
  ) {}

  ionViewWillEnter() {}


  closeModal() {

    this.orderHeadId$.pipe(
      concatMap(orderheadId => {
        return this.http.post(`${environment.api.url}/orderhead/setstatus`, {
          orderHeadId: orderheadId,
          statusCode: 'EDIT'
        })
      })
    ).subscribe(r => {
      this.payment.status.next('WAITING_FOR_USER_INPUT');
      this.modalCtrl.dismiss();
    });
  }
}
