import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RestaurantService } from '../restaurant/restaurant.service';
import * as moment from 'moment';
import { Plugins } from '@capacitor/core';
import { from, Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { Table } from '../../models/table.model';
import { TableData } from '../../interfaces/table-data.interface';
//import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root'
})

export class TableService {

  private _table = new BehaviorSubject<Table>(null);

  constructor(
    private http: HttpClient, 
    private restaurantService: RestaurantService
    //,private cartService:  CartService,

    ) {}

  // get table () {
  //   return this._table.asObservable().pipe(map(table => {
  //     if (table) {
  //       return table;
  //     } else {
  //       return null;
  //     }
  //   }));
  // }

  // get retriveTable () {
  //   return from(Plugins.Storage.get({key: 'selected-table'})).pipe(
  //     map(storedData => {
  //       if (storedData) {
  //         const parsedData = JSON.parse(storedData.value);
  //         return parsedData ? parsedData : null;
  //       }
  //     }),
  //     tap(data => {
  //       if (data && data.table) {
  //         //this._table.next(data.table);
  //         return data.table;
  //       }

  //       // this._table.next(null);
  //       return null;
  //     })
  //     );
  // }

  filterTable = function (array: any[], needle: number) {
    const selected = array.filter(item => {
      return +needle === +item.number;
    });
    return selected.length ? selected[0] : [];
  };

  getRestaurantByTable(tableId: number, languageId = 1) {
    return this.http.get(environment.api.url + `/table/${tableId}/restaurantalldata/language/${languageId}`)
    .pipe(map(resData => {
        return resData;
    }));
  }
}
