import { Component, OnInit, OnDestroy } from '@angular/core';

import { Platform, NavController, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

import { Pages } from './interfaces/pages';
import { AuthService } from './auth/auth.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from './models/user.model';
import { TableService } from './providers/table/table.service';
import { CartService, RestaurantService } from './providers';
import { Table } from './models/table.model';
import { Plugins } from '@capacitor/core';
import { AnalyticsService } from './providers/analytics/analytics.service';
import { Title } from '@angular/platform-browser';
import { WonderPush } from '@ionic-native/wonderpush/ngx';
import { Network } from '@ionic-native/network/ngx';
import { IosAddHomescreenComponent } from './components/ios-add-homescreen/ios-add-homescreen.component'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public appPages: Array<Pages>;
  public user: User;
  private authSub: Subscription;
  private disconnectSubscription: Subscription;
  private prevoiusAuthState = false;
  public deferredPrompt;
  public isIos = false;
  public displayIosAddToHome = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private translateService: TranslateService,
    public navCtrl: NavController,
    private authService: AuthService,
    private router: Router,
    public route: ActivatedRoute,
    private tableService: TableService,
    private cartService: CartService,
    private restaurantService: RestaurantService,
    private analyticsService: AnalyticsService,
    private title: Title,
    private wonderPush: WonderPush,
    private network: Network,
    public toastController: ToastController
  ) {

    if (this.platform.is('iphone')) {
      this.isIos = true;
    }

    this.authService.user.subscribe(user => {
      if (!user) {
        // this.router.navigateByUrl('/login');
      } else {
        this.user = user;

      }
    });
    this.appPages = [
      {
        title: 'app.pages.homelocation.label.searchvenue',
        url: '/restaurant-search',
        direct: 'forward',
        icon: 'search'
      },
      {
        title: 'app.pages.nearby.title.header',
        url: '/nearby',
        direct: 'forward',
        icon: 'compass'
      },
      {
        title: 'app.pages.latestorders.title.header',
        url: '/latest-orders',
        direct: 'forward',
        icon: 'list-sharp'
      },
      {
        title: 'app.pages.menu.support',
        url: '/support',
        direct: 'forward',
        icon: 'help-buoy'
      },
      {
        title: 'app.pages.settings.title.header',
        url: '/settings',
        direct: 'forward',
        icon: 'cog'
      }
    ];

    this.initializeApp();
  }

  isInStandaloneMode() {
    const navigator:any = window.navigator;
    return ('standalone' in navigator) && (navigator.standalone);
  } 

  ngOnInit() {
    this.authSub = this.authService.userIsAuthenticated.subscribe(isAuth => {
      if (!isAuth && this.prevoiusAuthState !== isAuth) {
        this.router.navigateByUrl('/login');
      }
      this.prevoiusAuthState = isAuth;
    });
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    this.disconnectSubscription.unsubscribe();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Internet kapcsolódási probléma...',
      duration: 10000
    });
    toast.present();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      // wonderpush native
      // this.wonderPush.subscribeToNotifications();

      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);

      // watch network for a disconnection
      this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
        this.presentToast();
      });

      if (this.platform.is('iphone') && !this.isInStandaloneMode()) {
        this.displayIosAddToHome = true;
      }

      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });

      this.analyticsService.startTrackerWithId(environment.analytics.tracker);
      this.router.events.subscribe(event => {
        //observe router and when it start navigation it will track the view
        if (event instanceof NavigationStart) {
          let title = this.title.getTitle();
          //get title if it was sent on state
          if (this.router.getCurrentNavigation().extras.state) {
            title = this.router.getCurrentNavigation().extras.state.title;
          }
          //pass url and page title 
          this.analyticsService.trackView(event.url, title);
        }
      });

      // Set language of the app.
      this.translateService.addLangs(['en', 'hu']);
      this.translateService.setDefaultLang(environment.language);
      this.translateService.use(environment.language);


      const tableId = this.platform.getQueryParam('tableId');

      this.authService.user.subscribe(user => {
        if (!user) {

          if (tableId) {
            const data = { tableId: tableId };
            Plugins.Storage.set({key: 'redirect-after-login', value: JSON.stringify(data)});
          }
        } else {

          if (tableId) {
            this.tableService.getRestaurantByTable(+tableId).subscribe((data: any) => {
              const venue = this.restaurantService.createRestaurantObject(data);
              this.restaurantService.setSelectedPlace(venue).then(() => {

                const tableData = venue.tables.filter((t: Table) => {
                  return +tableId === +t.id;
                });
                const table = new Table(tableData[0].id, tableData[0].id, tableData[0].number, tableData[0].name);

                this.cartService.setSelectedTable(table);

                this.navCtrl.navigateForward(`/places/restaurant/${venue.id}`);
              });
            });
          }

        }
      });

    }).catch(() => {
      // Set language of the app.
      this.translateService.setDefaultLang(environment.language);
      this.translateService.use(environment.language);
      this.translateService.getTranslation(environment.language).subscribe(translations => {
        this.translate.setTranslations(translations);
      });
    });
  }

  onAddToHomeClick() {
    // false when user set declined
    if (this.deferredPrompt !== undefined && this.deferredPrompt !== null && this.deferredPrompt !== false) {
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
        } else {
          //console.log('User dismissed the A2HS prompt');
        }
        // We no longer need the prompt.  Clear it up.
        this.deferredPrompt = null;
      });
    }
  }

  oncancelIosAddHome() {
    this.displayIosAddToHome = false;
  }

  onAddToHomeCancelClick() {
    this.deferredPrompt = false;
  }

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  logout() {
    this.authService.logout();
    this.navCtrl.navigateRoot('login');
  }
}
