import { Component, Input } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import {
  DishService,
  CartService
} from '../../../providers';

import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { delay, filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
  }

    let checked = 0;
    Object.keys(value).forEach(key => {
      if (value[key] === true) {
        checked++;
      }
    });

    if (checked === 0) {
      return { atLeastSelected: 'Not enough selected' };
    }

    return null;
  };
}

@Component({
  selector: 'app-cart',
  templateUrl: './menu-item-with-options.page.html',
  styleUrls: ['./menu-item-with-options.page.scss'],
})

export class MenuItemWithOptionsPage {
  form: FormGroup;
  fb: FormBuilder;

  @Input() cartService: CartService;
  @Input() menuItem: any; 
  quantity = 1;

  extraOptions = [];
  comboPrice = null;

  constructor(
    public navCtrl: NavController,
    public dishService: DishService,
    public route: Router,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.form = new FormGroup({});

    // create forms controls
    this.menuItem.options_array.forEach(optionGroup => {
      if (!optionGroup.is_multiselect) {  
        this.form.addControl(`group-${optionGroup.id}`,
          new FormControl(null, {
            validators: [Validators.required]
          }));
      } else {
        // multiselect
        const group = new FormGroup({});

        optionGroup.options.forEach(option => {
          group.addControl(`option-${option.id}`,
            new FormControl(null));
        });

        if (optionGroup.is_mandatory) {
          group.addValidators([requireCheckboxesToBeCheckedValidator()]);
        }

        this.form.addControl(`group-${optionGroup.id}`, group);
      }
    });

    this.form.statusChanges
      .pipe( tap(() => {
        this.comboPrice = null;
      }),
        delay(700), // wait to update form controls, if there are child list to display than form became INVALID in a second
        filter((status: string) => { return this.form.valid; }),
        switchMap(_ => {
          const options = this.generateOptionsArray(this.form.controls);
          return this.cartService.getOptionPrice(this.menuItem.id, this.quantity, options);
      }),
        )
      .subscribe((response:any) => this.comboPrice = response.price);
  }

  generateOptionsArray(controls) {
    let options = {
      menuItems: [],
      options: [],
    };

    Object.keys(controls).forEach(key => {
        if (key.includes('unit-group-')) {
          options.options.push(controls[key].value);
        } else {
          if (controls[key].value.id) {
            if (controls[key].value.is_menu_item) {
              options.menuItems.push(controls[key].value.id);
            } else {
              options.options.push(controls[key].value.id);
            }

          } else {
            // oh yes, the checkbox values are null|true
            // and then how do I know the id of the goddamn options ???
            // it is set on the object key like { option-10: true }

            // I know I know, there must be a better solution, let me know if you have one
            Object.keys(controls[key].value).forEach(k => {
              if (controls[key].value[k]) {
                const id = k.split('option-')[1];
                options.options.push(id);
              }
            });
          }
        }
    });

    return options;
  }

  ionViewWillEnter() {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onOptionsSelect($event) {
    const groups = $event.target.value.child_options;

    if (this.extraOptions.length) {
      this.extraOptions.forEach(group => {
        this.form.removeControl(`unit-group-${group.id}`);
      });
    }
    if (groups && groups.length) {
            groups.forEach(group => {
              this.form.addControl(
                  `unit-group-${group.id}`,
                  new FormControl(null, { validators: [Validators.required]})
                );
            });

      
          this.extraOptions = groups;
          
        } else {
          this.extraOptions = [];
        }
  }

  addToCart() {
    const options = this.generateOptionsArray(this.form.controls);
    this.cartService.addtoCart(this.menuItem.id, this.quantity, options).subscribe(r => {
      this.closeModal();
    });
  }

  increaseQuantity() {
    this.quantity++;

    const options = this.generateOptionsArray(this.form.controls);
    return this.cartService.getOptionPrice(this.menuItem.id, this.quantity, options).subscribe((response:any) => this.comboPrice = response.price);

  }

  decreaseQuantity() {
    if (this.quantity <= 1) {
      this.quantity = 1;
    } else {
      this.quantity--;
    }

    const options = this.generateOptionsArray(this.form.controls);
    return this.cartService.getOptionPrice(this.menuItem.id, this.quantity, options).subscribe((response:any) => this.comboPrice = response.price);
  }

}
