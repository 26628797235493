import { Component, Input } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import {
  DishService,
  CartService
} from '../../../providers';

import { BehaviorSubject, from, Observable } from 'rxjs';
import { Restaurant } from '../../../models/restaurant.model';
import { Plugins } from '@capacitor/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-cart',
  templateUrl: './under-eighteen.page.html',
  styleUrls: ['./under-eighteen.page.scss'],
})

export class UnderEighteenPage {

  @Input() cartService: CartService;
  @Input() menuItem: any; 
  @Input() quantity: number;
  @Input() openMenuOptionsModal: boolean;

  constructor(
    public navCtrl: NavController,
    public dishService: DishService,
    public route: Router,
    private modalCtrl: ModalController,
    private auth: AuthService
  ) {}

  ionViewWillEnter() {
  }

  closeModal() {
    this.auth.setOverEightTeenConfirmDate(new Date());
    if (this.openMenuOptionsModal) {
      this.cartService.openMenuOptionsModal(this.menuItem);
    } else {
      this.cartService.addtoCart(this.menuItem.id, this.quantity, []).subscribe();
    }
    this.modalCtrl.dismiss();
  }
}
