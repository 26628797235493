import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { Plugins } from '@capacitor/core';
import { from, Observable } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() {}

  setApiUrl(val: string) {
    return Plugins.Storage.set({key: 'apiUrl', value: val});
  }

  get apiUrl() {

    return from(Plugins.Storage.get({key: 'apiUrl'})).pipe(
        map(obj => {
          if (obj) {
            return obj.value;
          } else {
            return null;
          }
        })
    );
  }

}
