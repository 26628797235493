import { Component, ViewChild } from '@angular/core';
import { NavController, ModalController, IonItemSliding } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

import {
  DishService,
  CartService,
  RestaurantService
} from '../../../providers';

// import { RequestTableNumberPage } from '../table/request-table-number/request-table-number.page';
// import { TableService } from '../../../providers/table/table.service';
// import { BehaviorSubject } from 'rxjs';
// import { concatMap, take, switchMap, tap } from 'rxjs/operators';
import { Restaurant } from '../../../models/restaurant.model';

@Component({
  selector: 'app-restaurant-info',
  templateUrl: './restaurant-info.page.html',
  styleUrls: ['./restaurant-info.page.scss'],
})

export class RestaurantInfoPage {

  restaurant: Restaurant;
  address: string;
  isLoading = true;
  key = environment.maps.key;

  constructor(
    public navCtrl: NavController,
    public dishService: DishService,
    public cartService: CartService,
    public route: Router,
    private modalCtrl: ModalController,
    private restaurantService: RestaurantService
  ) {}

  ionViewWillEnter() {
    this.restaurantService.selectedPlace.subscribe((restaurant: Restaurant) => {
      this.restaurant = restaurant;
      this.address = this.restaurantService.formatAddress(this.restaurant);
      this.isLoading = false;
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
