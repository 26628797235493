import {Component, ViewChild} from '@angular/core';
import {IonItemSliding, ModalController, NavController} from '@ionic/angular';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

import {CartService, DishService, RestaurantService} from '../../../providers';
import {TableService} from '../../../providers/table/table.service';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {concatMap, take, tap} from 'rxjs/operators';
import {AuthService} from '../../../auth/auth.service';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.page.html',
    styleUrls: ['./cart.page.scss'],
})

export class CartPage {
    orders: Array<any>;
    tableNumber: number;
    emitter = new BehaviorSubject<number>(null);

    @ViewChild('slidingList') slidingList: IonItemSliding;

    constructor(public navCtrl: NavController,
                public dishService: DishService,
                public cartService: CartService,
                public route: Router,
                private modalCtrl: ModalController,
                private http: HttpClient,
                private tableService: TableService,
                private restaurantService: RestaurantService,
                private authService: AuthService) {
    }

    ionViewWillEnter() {
        this.cartService.retriveTable.pipe(take(1),
            concatMap(table => {
                return this.cartService.table;
            })
        ).subscribe(tblObj => {
            if (tblObj) {
                this.tableNumber = tblObj.number;
                this.emitter.next(tblObj.number);
            } else {
                this.emitter.next(null);
            }
        });

        this.cartService.fetchCart().pipe(tap(fetched => {
            this.orders = this.cartService.getOrders();
        })).subscribe();
    }

    async onRequestTable() {
        forkJoin([
            this.restaurantService.selectedPlace.pipe(take(1))
        ]).pipe(tap(([restaurant]) => {
            this.cartService.presetntRequestTableModal(restaurant.id);
        })).subscribe();
    }

    async removeOrder(orderItem) {
        this.cartService.removefromCart(orderItem)
            .subscribe(() => {
                this.getOrders();
            });

        await this.slidingList.close().then((a) => {
        });
    }

    getOrders() {
        this.orders = this.cartService.getOrders();
    }

    // minus adult when click minus button
    minusQtd(order) {
        this.cartService.editQtdOrder(order, 'minus');
    }

    // plus adult when click plus button
    plusQtd(orderItem) {
        this.cartService.editQtdOrder(orderItem, 'plus');
    }

    openCheckout() {
        this.route.navigate(['checkout']);
        this.modalCtrl.dismiss();
        // this.navCtrl.navigateForward('checkout/');
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    increaseOrderItemQuantity(orderItem) {
        this.cartService.increaseOrderItemQuantity(orderItem);
    }

    decreaseOrderItemQuantity(orderItem) {
        this.cartService.decreaseOrderItemQuantity(orderItem);
    }
}
